import moment from "moment-timezone";
import { ActionsCovidFilterType, VouchersProps, WorkersProps } from "interfaces/workers";
import { EntriesProps } from "interfaces/entries";
import { entriesTypesDocuments, provisioningAccounts } from "./constans";
import { AssetProps, SurfaceProps } from "interfaces/inventory";
import { TypeAssetsEnum, SurfacesUrlParams } from "interfaces/inventory.enums";
import { AccountProps } from "interfaces/accounts";
import { notification } from "antd";
import { consultSunat, getCustomerForVoucher } from "requests/global";
import { SystemRolesEnum } from "interfaces/global.enums";
import { ProvisioningTabsEnum } from "interfaces/accounting.enum";
import { OriginProps, VoucherProps } from "interfaces/vouchers";
import napformat from "./napformat";

export const getCovidDateParams = (filters: ActionsCovidFilterType, year: number) => {
  let firstDate = "",
    secondDate = "";

  if (filters.type === "day") {
    firstDate = filters.date;
    secondDate = filters.date;
  } else if (filters.type === "month") {
    firstDate = moment(`${year}-${filters.month}-15`).startOf("month").format();
    secondDate = moment(`${year}-${filters.month}-15`).endOf("month").format();
  } else if (filters.type === "year") {
    firstDate = moment(`${filters.year}-${filters.month}-15`).startOf("year").format();
    secondDate = moment(`${filters.year}-${filters.month}-15`).endOf("year").format();
  } else {
    firstDate = filters.date;
    secondDate = filters.date1;
  }
  return { firstDate, secondDate };
};

export const formatRole = (role: SystemRolesEnum | undefined) => {
  switch (role) {
    case SystemRolesEnum.SUPERNAP:
      return "SUPERNAP";
    case SystemRolesEnum.SUPERADMIN:
      return "SUPER-ADMINISTRADOR";
    case SystemRolesEnum.ADMIN:
      return "ADMINISTRADOR";
    case SystemRolesEnum.ACCOUNTANT:
      return "CONTADOR";
    default:
      return role;
  }
};

export const removeAccents = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const filterOptions = (inputValue: string, option: any) => {
  const title = removeAccents(String(option?.title || option?.children).toLowerCase());
  return title.includes(inputValue.toLowerCase());
};

const defaultResponse = { t_doc: "", n_doc: "", nombre_o_razon_social: "", isError: false };
export const consultReniec = async (ndoc: string, forVoucher?: boolean) => {
  const tdoc = ndoc.length === 8 ? "dni" : ndoc.length === 11 ? "ruc" : undefined;
  if (!tdoc) {
    notification.warning({ message: "Tienes que ingresar un número de documento válido" });
    return defaultResponse;
  }
  try {
    let res: Record<string, unknown> = {};

    if (forVoucher) res = await getCustomerForVoucher(tdoc, ndoc);
    else res = await consultSunat(tdoc, ndoc);

    return {
      t_doc: tdoc,
      n_doc: res[forVoucher ? "ruc" : tdoc],
      nombre_o_razon_social: res.nombre || res.nombre_o_razon_social,
      isError: false,
    };
  } catch (error) {
    notification["error"]({
      message: "NO SE ENCONTRÓ LA INFORMACIÓN SOLICITADA.",
      description: `No se encontraron coincidencias para '${tdoc.toUpperCase()}: ${ndoc}', verifica si la información es correcta e intente otra vez.`,
    });
    return { ...defaultResponse, isError: true };
  }
};

export const getYearsForSelect = () => {
  const years: Array<number> = [];
  for (let i = moment().year() - 5; i <= moment().year() + 2; i++) {
    years.push(i);
  }
  return years;
};

export const getDocTypes = (key: string, year: number) => {
  switch (key) {
    case "01":
      return `COMPROBANTE DE PAGO ─ ENERO ${year}`;
    case "02":
      return `COMPROBANTE DE PAGO ─ FEBRERO ${year}`;
    case "03":
      return `COMPROBANTE DE PAGO ─ MARZO ${year}`;
    case "04":
      return `COMPROBANTE DE PAGO ─ ABRIL ${year}`;
    case "05":
      return `COMPROBANTE DE PAGO ─ MAYO ${year}`;
    case "06":
      return `COMPROBANTE DE PAGO ─ JUNIO ${year}`;
    case "07":
      return `COMPROBANTE DE PAGO ─ JULIO ${year}`;
    case "08":
      return `COMPROBANTE DE PAGO ─ AGOSTO ${year}`;
    case "09":
      return `COMPROBANTE DE PAGO ─ SETIEMBRE ${year}`;
    case "10":
      return `COMPROBANTE DE PAGO ─ OCTUBRE ${year}`;
    case "11":
      return `COMPROBANTE DE PAGO ─ NOVIEMBRE ${year}`;
    case "12":
      return `COMPROBANTE DE PAGO ─ DICIEMBRE ${year}`;
    case "13":
      return `COMPROBANTE DE PAGO ─ GRATIFICACIÓN JULIO ${year}`;
    case "14":
      return `COMPROBANTE DE PAGO ─ GRATIFICACIÓN DICIEMBRE ${year}`;
    case "15":
      return `COMPROBANTE DE PAGO ─ CTS MAYO ${year}`;
    case "16":
      return `COMPROBANTE DE PAGO ─ CTS NOVIEMBRE ${year}`;
    case "17":
      return `CERTIFICADO DE 5TA CATEGORÍA ${year}`;
    case "18":
      return `CONTRATO DE TRABAJO ${year}`;
    default:
      return `OTROS ─ ${year}`;
  }
};

export const getDocTypesTemp = (year: number) => {
  return {
    "01": `COMPROBANTE DE PAGO ─ ENERO ${year}`,
    "02": `COMPROBANTE DE PAGO ─ FEBRERO ${year}`,
    "03": `COMPROBANTE DE PAGO ─ MARZO ${year}`,
    "04": `COMPROBANTE DE PAGO ─ ABRIL ${year}`,
    "05": `COMPROBANTE DE PAGO ─ MAYO ${year}`,
    "06": `COMPROBANTE DE PAGO ─ JUNIO ${year}`,
    "07": `COMPROBANTE DE PAGO ─ JULIO ${year}`,
    "08": `COMPROBANTE DE PAGO ─ AGOSTO ${year}`,
    "09": `COMPROBANTE DE PAGO ─ SETIEMBRE ${year}`,
    "10": `COMPROBANTE DE PAGO ─ OCTUBRE ${year}`,
    "11": `COMPROBANTE DE PAGO ─ NOVIEMBRE ${year}`,
    "12": `COMPROBANTE DE PAGO ─ DICIEMBRE ${year}`,
    "13": `COMPROBANTE DE PAGO ─ GRATIFICACIÓN JULIO ${year}`,
    "14": `COMPROBANTE DE PAGO ─ GRATIFICACIÓN DICIEMBRE ${year}`,
    "15": `COMPROBANTE DE PAGO ─ CTS MAYO ${year}`,
    "16": `COMPROBANTE DE PAGO ─ CTS NOVIEMBRE ${year}`,
    "17": `CERTIFICADO DE 5TA CATEGORÍA ${year}`,
    "18": `CONTRATO DE TRABAJO ${year}`,
  };
};

export const toRedirect = (url: string, target: string) => window.open(url, target);

export const formatWorkerData = (data: Array<WorkersProps>) => {
  return data.map((row) => ({
    ...row,
    fullname: `${row.f_name} ${row.l_name}`,
    document: `${row.t_doc}: ${row.n_doc}`,
  }));
};

export const formatWorkerVouchersData = (data: Array<VouchersProps>) => {
  return data
    .map((item) => ({
      ...item,
      colaborador: item.worker ? `${item.worker.f_name} ${item.worker.l_name}` : "─",
      date_upload: moment(item.date_upload).format("DD/MM/YYYY"),
      date_voucher: moment(item.date_voucher).format("DD/MM/YYYY"),
    }))
    .reverse();
};

export const formatEntriesData = (data: Array<EntriesProps>, onlyEnabled: boolean, year: number) => {
  return data
    .filter((item) => (onlyEnabled ? item.status === "HABILITADA" : true) && moment(item.date).year() === year)
    .map((item) => ({
      ...item,
      concepto: entriesTypesDocuments.find((td) => td.key === item.observation)?.label ?? "-",
    }))
    .reverse();
};

export const isHiddenByDelete = (payload: SurfaceProps | AssetProps, userId: string | undefined) => {
  return payload.memoInfo?.delete && payload.memoInfo?.user === userId && payload.memoInfo?.memorandum;
};

export const isHiddenByTransfer = (payload: SurfaceProps | AssetProps, userId: string | undefined) => {
  return payload.memoInfo?.transfer && payload.memoInfo?.user === userId && payload.memoInfo?.memorandum;
};

export const buildInventoryTitle = (type: SurfacesUrlParams | TypeAssetsEnum) => {
  switch (type) {
    case SurfacesUrlParams.GROUNDS:
      return "Terrenos";
    case SurfacesUrlParams.EDIFICATIONS:
      return "Edificaciones";
    case TypeAssetsEnum.MACHINERY:
      return "Equipos y Maquinaria";
    case TypeAssetsEnum.FURNITURE:
      return "Muebles";
    case TypeAssetsEnum.VEHICLES:
      return "Vehículos";
    case TypeAssetsEnum.EQUIPMENT:
      return "Enseres";
    case TypeAssetsEnum.INTANGIBLES:
      return "Intangibles";
    case TypeAssetsEnum.RELIGIOUS:
      return "Artículos religiosos";
    default:
      return type;
  }
};

export const firstPendingAction = (data: Array<SurfaceProps | AssetProps>) => {
  const aux = data.map((ev, index) => ({ ...ev, key: index }));
  return [
    ...aux?.filter((row) => (row?.memoInfo?.delete || row?.memoInfo?.transfer) && row.memoInfo?.memorandum),
    ...aux?.filter((row) => (!row?.memoInfo?.delete && !row?.memoInfo?.transfer) || !row.memoInfo?.memorandum),
  ];
};

export const filterInventoryAccounts = (type: TypeAssetsEnum | undefined, data: Array<AccountProps>) => {
  let accounts = [];

  switch (type) {
    case TypeAssetsEnum.MACHINERY:
      accounts = data.filter((el) => String(el.number).startsWith("336") || String(el.number).startsWith("337"));
      break;
    case TypeAssetsEnum.FURNITURE:
      accounts = data.filter((el) => String(el.number).startsWith("3351"));
      break;
    case TypeAssetsEnum.VEHICLES:
      accounts = data.filter((el) => String(el.number).startsWith("3341"));
      break;
    case TypeAssetsEnum.EQUIPMENT:
      accounts = data.filter((el) => String(el.number).startsWith("3352"));
      break;
    case TypeAssetsEnum.INTANGIBLES:
      accounts = data.filter((el) => String(el.number).startsWith("34") || String(el.number).startsWith("38"));
      break;
    default:
      accounts = data;
      break;
  }

  return accounts;
};

export const validateFormFields = (fields: object, excludesKeys?: Array<string>, withoutAlert?: boolean) => {
  const isCorrect = Object.entries(fields).every(([key, value]) => [...(excludesKeys || [])].includes(key) || value);
  if (!isCorrect && !withoutAlert)
    notification.warning({
      message: "Campos requeridos sin completar",
      description: "Llene todos los campos requeridos antes de continuar...",
    });
  return !isCorrect;
};

export const twoStringDigits = (value: number) => (value === 0 ? "Anual" : String(value).length === 1 ? `0${value}` : String(value));

export const buildExecutionBudgetTitle = (key: string) => {
  switch (key) {
    case "incomeResumeEx":
      return "RESUMEN DE INGRESOS";
    case "incomeDetailsEx":
      return "DETALLE DE INGRESOS";
    case "expensesResumeEx":
      return "RESUMEN DE GASTOS";
    case "expensesDetailsEx":
      return "DETALLE DE GASTOS";
    default:
      return "RESUMEN Y/O GASTOS";
  }
};

export const buildBudgetTitle = (key: string) => {
  switch (key) {
    case "incomeDetails":
      return "INGRESOS - PRESUPUESTO DE FUENTES DE FINANCIAMIENTO - DETALLE";
    case "incomeResume":
      return "INGRESOS - PRESUPUESTO DE FUENTES DE FINANCIAMIENTO - RESUMEN";
    case "expensesDetails":
      return "PRESUPUESTO DE OPERACIÓN";
    case "expensesResume":
      return "RESUMEN DEL PRESUPUESTO DE OPERACIÓN E INVERSIÓN";
    default:
      return "OTROS";
  }
};

export const buildEEGGPPNNTitle = (key: string) => {
  switch (key) {
    case "infoFinalEgresos":
      return "EGRESOS";
    case "infoFinalIngresos":
      return "INGRESOS";
    default:
      return "OTROS";
  }
};

export const buildProvisioningDetailTitle = (key: ProvisioningTabsEnum) => {
  switch (key) {
    case ProvisioningTabsEnum.ENTRIES:
      return "COBROS";
    case ProvisioningTabsEnum.PAYMENTS:
      return "PAGOS";
    default:
      return "OTROS";
  }
};

export const getVoucherCustomer = (voucher: VoucherProps) => ({
  ruc: String(typeof voucher.customer === "object" ? voucher.customer?.ruc || "" : voucher.ruc),
  nombre_o_razon_social: typeof voucher.customer === "object" ? voucher.customer?.nombre_o_razon_social || "" : voucher.customer,
  t_doc: typeof voucher.customer === "object" ? voucher.customer?.t_doc || "RUC" : voucher.ruc?.length === 8 ? "DNI" : "RUC",
});

export const formatProvisioningVoucherData = (item: VoucherProps, origins: Array<OriginProps>) => {
  const findAmount = item.details.find((ev) => provisioningAccounts.includes(ev.account?.number?.toString()?.substring(0, 2)));
  const findOrigin = origins.find((ev) => ev.id === item.origin);
  return {
    ...item,
    originName: findOrigin?.description,
    ruc: getVoucherCustomer(item).ruc,
    comment: item.details[0].comment,
    amount: findAmount ? napformat(findAmount.amount) : null,
    operation_date: moment(item.operation_date).format("DD/MM/YYYY"),
  };
};

export const formatVouchersData = (item: VoucherProps, origins: Array<OriginProps>, format: boolean = true) => {
  const findOrigin = origins.find((row) => row.id === item.origin);
  const typeProv = item.type_provisioning;

  const { provisioningSum, voucherSum } = item.details.reduce(
    (prev: any, next) => ({
      provisioningSum: (prev.provisioningSum += typeProv && typeProv === next.type_detail ? next.amount : 0),
      voucherSum: (prev.voucherSum += (next.amount * next.exchange_rate) / 2),
    }),
    { provisioningSum: 0, voucherSum: 0 }
  );

  const realAmount = typeProv ? (typeProv === item.type_detail ? voucherSum : provisioningSum) : voucherSum;

  return {
    ...item,
    originName: findOrigin?.description,
    ruc: getVoucherCustomer(item).ruc,
    comment: item.details[0]?.comment?.toUpperCase(),
    amount: napformat(realAmount),
    operation_date: format ? moment(item.operation_date).format("DD/MM/YYYY") : item.operation_date,
  };
};

export const buildQueryParams = (params: Record<string, any>) => {
  const auxParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (Array.isArray(value)) value.forEach((item) => auxParams.append(key, item.toString()));
    else auxParams.append(key, String(value));
  });

  return auxParams;
};

export const validateActiveWorker = (worker: WorkersProps, headquarter: string, status: boolean) => {
  return worker.dataHeadquarters?.find((row) => {
    return row.headquarter === headquarter && row.active === status;
  });
};

export const equals = (a: any, b: any) => {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (typeof a === "string" || typeof a === "number" || typeof a === "boolean") return a === b;
  if (typeof a === "object") {
    for (const key in a) {
      if (!b.hasOwnProperty(key) || !equals(a[key], b[key])) return false;
    }
    return true;
  }
  return false;
};
