import { lazy } from "react";
import { PropsRoutes } from "interfaces/global";
import { SystemRolesEnum } from "interfaces/global.enums";
import Loadable from "./Loadable";

// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import("pages/errors/Page404")));
// ----------------------------------------------------------------------
const PrivateLayout = Loadable(lazy(() => import("layouts/Private")));
const PublicLayout = Loadable(lazy(() => import("layouts/Public")));
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import("pages/public/Login")));
const Home = Loadable(lazy(() => import("pages/public/Home")));
const ForgotPassword = Loadable(lazy(() => import("pages/public/ForgotPassword")));
const ConfirmToken = Loadable(lazy(() => import("pages/public/Token")));
const Privacy = Loadable(lazy(() => import("pages/public/Privacy")));
// ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import("pages/modules/Global/Dashboard")));
const Profile = Loadable(lazy(() => import("pages/modules/Global/Profile")));
// ACCOUNTING
const VoucherList = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/VoucherList")));
const VoucherFast = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/VoucherFast")));
const CreateVoucher = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/CreateVoucher")));
const VoucherDetail = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/DetailVoucher")));
const EditVoucher = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/EditVoucher")));
const VoucherImport = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/VoucherImport")));
const VoucherProposals = Loadable(lazy(() => import("pages/modules/Accounting/containers/voucher/Proposals")));
const Provisioning = Loadable(lazy(() => import("pages/modules/Accounting/containers/Provisioning")));
const ProvisioningDetails = Loadable(lazy(() => import("pages/modules/Accounting/containers/ProvisioningDetails")));
const AccountAssistant = Loadable(lazy(() => import("pages/modules/Accounting/containers/AccountAssistant")));
const AccountingReports = Loadable(lazy(() => import("pages/modules/Accounting/containers/Reports")));
const AccountingReportSwitcher = Loadable(lazy(() => import("pages/modules/Accounting/components/reports/ReportSwitcher")));
const AccountingFiles = Loadable(lazy(() => import("pages/modules/Accounting/containers/Files")));
const Budget = Loadable(lazy(() => import("pages/modules/Accounting/containers/Budget")));
const WorkingPeriod = Loadable(lazy(() => import("pages/modules/Accounting/containers/settings/WorkingPeriod")));
const SunatKeys = Loadable(lazy(() => import("pages/modules/Accounting/containers/settings/SunatKeys")));
const Costs = Loadable(lazy(() => import("pages/modules/Accounting/containers/settings/Costs")));
const TransferAccountPlan = Loadable(lazy(() => import("pages/modules/Accounting/containers/settings/TransferAccountPlan")));
// ACCOUNTS
const Accounts = Loadable(lazy(() => import("pages/modules/Accounts/containers/Accounts")));
const AccountsImport = Loadable(lazy(() => import("pages/modules/Accounts/containers/Import")));
// INVENTORY MODULE
const InventoryMain = Loadable(lazy(() => import("pages/modules/Inventory/containers/MainInventory")));
const Surfaces = Loadable(lazy(() => import("pages/modules/Inventory/containers/Surfaces")));
const Assets = Loadable(lazy(() => import("pages/modules/Inventory/containers/Assets")));
const Consumable = Loadable(lazy(() => import("pages/modules/Inventory/containers/Consumable")));
const Locations = Loadable(lazy(() => import("pages/modules/Inventory/containers/Locations")));
const Categories = Loadable(lazy(() => import("pages/modules/Inventory/containers/Categories")));
const InventoryReports = Loadable(lazy(() => import("pages/modules/Inventory/containers/Reports")));
const LocationReport = Loadable(lazy(() => import("pages/modules/Inventory/containers/reports/LocationReport")));
const CategoriesReport = Loadable(lazy(() => import("pages/modules/Inventory/containers/reports/CategoryReport")));
const SubCategoriesReport = Loadable(lazy(() => import("pages/modules/Inventory/containers/reports/SubCategoryReport")));
const Memorandum = Loadable(lazy(() => import("pages/modules/Inventory/containers/Memorandum")));
const Search = Loadable(lazy(() => import("pages/modules/Inventory/containers/Search")));
const ControlInventory = Loadable(lazy(() => import("pages/modules/Inventory/containers/Control")));
// ENTRIES MODULE
const EntriesList = Loadable(lazy(() => import("pages/modules/Entries/containers/Entries")));
const EntriesDocuments = Loadable(lazy(() => import("pages/modules/Entries/containers/Documents")));
// WORKERS MODULE
const Vouchers = Loadable(lazy(() => import("pages/modules/Workers/containers/Vouchers")));
const VoucherByCompany = Loadable(lazy(() => import("pages/modules/Workers/containers/VouchersByCompany")));
const WorkerIncidents = Loadable(lazy(() => import("pages/modules/Workers/containers/Incidents")));
const WorkerMemorandum = Loadable(lazy(() => import("pages/modules/Workers/containers/Memorandum")));
const WorkerInformation = Loadable(lazy(() => import("pages/modules/Workers/containers/Information")));
const Workers = Loadable(lazy(() => import("pages/modules/Workers/containers/Workers")));
const Vacations = Loadable(lazy(() => import("pages/modules/Workers/containers/Vacations")));
const Permits = Loadable(lazy(() => import("pages/modules/Workers/containers/Permits")));
const WorkerVouchersDetail = Loadable(lazy(() => import("pages/modules/Workers/containers/WorkerVouchersDetail")));
const Covid19Affidavit = Loadable(lazy(() => import("pages/modules/Workers/containers/Affidavit/Covid19")));
const HealthAffidavit = Loadable(lazy(() => import("pages/modules/Workers/containers/Affidavit/Health")));
const PrivateStaffAffidavit = Loadable(lazy(() => import("pages/modules/Workers/containers/Affidavit/PrivateStaff")));
const AttendanceFormats = Loadable(lazy(() => import("pages/modules/Workers/containers/AttendanceFormats")));
const WorkerAttendances = Loadable(lazy(() => import("pages/modules/Workers/containers/Attendances/WorkerAttendances")));
const WorkerWorkingHours = Loadable(lazy(() => import("pages/modules/Workers/containers/Attendances/WorkerWorkingHours")));
const WorkerHolidays = Loadable(lazy(() => import("pages/modules/Workers/containers/Holidays")));
const WorkerAttendanceReport = Loadable(lazy(() => import("pages/modules/Workers/containers/Attendances/WorkerAttendanceReport")));
const WorkerAttendanceMonthlyReport = Loadable(
  lazy(() => import("pages/modules/Workers/containers/Attendances/Reports/WorkerAttendanceMonthlyReport"))
);
const WorkerAttendanceAbsencesReport = Loadable(
  lazy(() => import("pages/modules/Workers/containers/Attendances/Reports/WorkerAttendanceAbsencesReport"))
);
const WorkerAttendanceLatenessReport = Loadable(
  lazy(() => import("pages/modules/Workers/containers/Attendances/Reports/WorkerAttendanceLatenessReport"))
);
const WorkerDevices = Loadable(lazy(() => import("pages/modules/Workers/containers/Devices")));
const ImportWorkers = Loadable(lazy(() => import("pages/modules/Workers/containers/Import")));
const ConfigWorkers = Loadable(lazy(() => import("pages/modules/Workers/containers/ConfigWorkers")));
const ImportAttendanceDetails = Loadable(lazy(() => import("pages/modules/Workers/containers/ImportAttendanceDetails")));
const Committee = Loadable(lazy(() => import("pages/modules/Workers/containers/OccupationalHealth/Committee")));
const CommitteeSexualHarassment = Loadable(lazy(() => import("pages/modules/Workers/containers/OccupationalHealth/CommitteeSexualHarassment")));
const MisionVision = Loadable(lazy(() => import("pages/modules/Workers/containers/MisionVision")));
const IncomeTax = Loadable(lazy(() => import("pages/modules/Workers/containers/IncomeTax")));
const SendEmails = Loadable(lazy(() => import("pages/modules/Workers/containers/SendEmails")));
// WORKERS PAYROLLS
const PayrollRegister = Loadable(lazy(() => import("pages/modules/Payrolls/containers/PayrollRegister")));
const UpdatePayrollInformation = Loadable(lazy(() => import("pages/modules/Payrolls/containers/UpdateInformation")));
const PayrollConcepts = Loadable(lazy(() => import("pages/modules/Payrolls/containers/PayrollConcepts")));
const PensionSystems = Loadable(lazy(() => import("pages/modules/Payrolls/containers/PensionSystems")));
// INCIDENTS MODULE
const IncidentsPublic = Loadable(lazy(() => import("pages/modules/Incidents/containers/Public")));
const Incidents = Loadable(lazy(() => import("pages/modules/Incidents")));
const IncidentDetail = Loadable(lazy(() => import("pages/modules/Incidents/containers/Details")));
const IncidentReport = Loadable(lazy(() => import("pages/modules/Incidents/containers/Report")));
// CLAIMS MODULE
const ClaimsPublic = Loadable(lazy(() => import("pages/modules/Claims/containers/Public")));
const Claims = Loadable(lazy(() => import("pages/modules/Claims")));
// CONFIGURATION MODULE
const Companies = Loadable(lazy(() => import("pages/modules/Config/Companies")));
const Headquarters = Loadable(lazy(() => import("pages/modules/Config/Headquarters")));
const Users = Loadable(lazy(() => import("pages/modules/Config/Users")));
const UsersDisabled = Loadable(lazy(() => import("pages/modules/Config/UsersDisabled")));
const UsersAssign = Loadable(lazy(() => import("pages/modules/Config/Assign")));
// MESSAGES MODULE
const Messages = Loadable(lazy(() => import("pages/modules/Message/containers/Messages")));

// OCCUPATIONAL_HEALTH
const ProcessIPERC = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Process")));
const IPERC = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/IPERC")));
const Acta = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Acta")));
const Events = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Events")));
const Positions = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Positions")));
const Activities = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Activities")));
const LocationsIPERC = Loadable(lazy(() => import("pages/modules/OccupationalHealth/containers/Locations")));

// REGISTER ASSOCIATES
const ListAssociates = Loadable(lazy(() => import("pages/modules/RegisterAssociates/containers/ListAssociates")));
const Communities = Loadable(lazy(() => import("pages/modules/RegisterAssociates/containers/Communities")));

const routes: Array<PropsRoutes> = [
  {
    path: "/",
    element: PublicLayout,
    isPrivate: false,
    roles: [],
    children: [
      {
        path: "",
        roles: [],
        element: Home,
      },
      {
        path: ":url/login",
        roles: [],
        element: Login,
      },
      {
        path: ":url/incidencias",
        element: IncidentsPublic,
        roles: [],
      },
      {
        path: ":url/reclamos",
        element: ClaimsPublic,
        roles: [],
      },
      {
        path: "forgotpassword",
        element: ForgotPassword,
        roles: [],
      },
      {
        path: "auth/reset_password/:token",
        element: ConfirmToken,
        roles: [],
      },
      {
        path: "privacy",
        element: Privacy,
        roles: [],
      },
      {
        path: "*",
        roles: [],
        element: Page404,
      },
    ],
  },
  {
    path: "/",
    roles: Object.values(SystemRolesEnum),
    element: PrivateLayout,
    isPrivate: true,
    children: [
      {
        path: "",
        element: Dashboard,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "profile",
        element: Profile,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: ":url/incidencias",
        element: IncidentsPublic,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: ":url/reclamos",
        element: ClaimsPublic,
        roles: Object.values(SystemRolesEnum),
      },
      // REGISTER_ASSOCIATES
      {
        path: "associates/list",
        element: ListAssociates,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "associates/communities",
        element: Communities,
        roles: Object.values(SystemRolesEnum),
      },
      // OCCUPATIONAL_HEALTH
      {
        path: "occupational-health/configuration/processes",
        element: ProcessIPERC,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/iperc",
        element: IPERC,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/acta",
        element: Acta,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/events",
        element: Events,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/configuration/positions",
        element: Positions,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/configuration/activities",
        element: Activities,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/configuration/locations",
        element: LocationsIPERC,
        roles: Object.values(SystemRolesEnum),
      },
      // ACCOUNTING
      {
        path: "accounting/vouchers/list",
        element: VoucherList,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/fastbox",
        element: VoucherFast,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/create",
        element: CreateVoucher,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/list/:voucherId",
        element: VoucherDetail,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/edit/:voucherId",
        element: EditVoucher,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/import",
        element: VoucherImport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/vouchers/proposals",
        element: VoucherProposals,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/provisioning",
        element: Provisioning,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/provisioning/:type/:voucherId",
        element: ProvisioningDetails,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/account-assistant",
        element: AccountAssistant,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/reports",
        element: AccountingReports,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/reports/:type",
        element: AccountingReportSwitcher,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/files",
        element: AccountingFiles,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/budget",
        element: Budget,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/settings/sunat-keys",
        element: SunatKeys,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "accounting/settings/working-period",
        element: WorkingPeriod,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "accounting/settings/costs",
        element: Costs,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounting/settings/transfer-accounts",
        element: TransferAccountPlan,
        roles: Object.values(SystemRolesEnum),
      },
      // ACCOUNTS
      {
        path: "accounts/list",
        element: Accounts,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "accounts/import",
        element: AccountsImport,
        roles: Object.values(SystemRolesEnum),
      },
      // INVENTORY
      {
        path: "inventory",
        element: InventoryMain,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/:type/surfaces",
        element: Surfaces,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/:type/assets",
        element: Assets,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/consumible",
        element: Consumable,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/locations",
        element: Locations,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/categories",
        element: Categories,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/reports",
        element: InventoryReports,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/reports/location",
        element: LocationReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/reports/categories",
        element: CategoriesReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/reports/subcategories",
        element: SubCategoriesReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/memorandum",
        element: Memorandum,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/search",
        element: Search,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "inventory/control",
        element: ControlInventory,
        roles: Object.values(SystemRolesEnum),
      },
      // ENTRIES
      {
        path: "entries/list",
        element: EntriesList,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "entries/documents",
        element: EntriesDocuments,
        roles: Object.values(SystemRolesEnum),
      },
      // WORKERS
      {
        path: "workers/vouchers/company",
        element: VoucherByCompany,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      {
        path: "workers/incometax",
        element: IncomeTax,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/send-emails",
        element: SendEmails,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/vouchers/:param",
        element: Vouchers,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/vouchers/:workerId/:from",
        element: WorkerVouchersDetail,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/list/:param",
        element: Workers,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/list/vacations",
        element: Vacations,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/permits",
        element: Permits,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/affidavit/covid19",
        element: Covid19Affidavit,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/affidavit/health",
        element: HealthAffidavit,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/affidavit/private-staff",
        element: PrivateStaffAffidavit,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/information",
        element: WorkerInformation,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/incidents",
        element: WorkerIncidents,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/memorandum",
        element: WorkerMemorandum,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendance-formats",
        element: AttendanceFormats,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendances",
        element: WorkerAttendances,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/working-hours",
        element: WorkerWorkingHours,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/holidays",
        element: WorkerHolidays,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendances/report",
        element: WorkerAttendanceReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendances/report/monthly",
        element: WorkerAttendanceMonthlyReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendances/report/absences",
        element: WorkerAttendanceAbsencesReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/attendances/report/lateness",
        element: WorkerAttendanceLatenessReport,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/import",
        element: ImportWorkers,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/config",
        element: ConfigWorkers,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "workers/attendances/devices",
        element: WorkerDevices,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/import/details",
        element: ImportAttendanceDetails,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/committee",
        element: Committee,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "occupational-health/committee/sexual-harassment",
        element: CommitteeSexualHarassment,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "workers/mission-vision",
        element: MisionVision,
        roles: Object.values(SystemRolesEnum),
      },
      // PAYROLLS
      {
        path: "payrolls/register",
        element: PayrollRegister,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      {
        path: "payrolls/concepts",
        element: PayrollConcepts,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      {
        path: "payrolls/update-information",
        element: UpdatePayrollInformation,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      {
        path: "payrolls/pension-systems",
        element: PensionSystems,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      // CLAIMS
      {
        path: "claims/list",
        element: Claims,
        roles: Object.values(SystemRolesEnum),
      },
      // INCIDENTS
      {
        path: "incidents/list",
        element: Incidents,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "incidents/detail/:incidentId",
        element: IncidentDetail,
        roles: Object.values(SystemRolesEnum),
      },
      {
        path: "incidents/report/:incidentId",
        element: IncidentReport,
        roles: Object.values(SystemRolesEnum),
      },
      // CONFIGURATION MODULE
      {
        path: "configuration/companies",
        element: Companies,
        roles: [SystemRolesEnum.SUPERNAP],
      },
      {
        path: "configuration/headquarters",
        element: Headquarters,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "configuration/users",
        element: Users,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "configuration/users/disabled",
        element: UsersDisabled,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "configuration/usersassign",
        element: UsersAssign,
        roles: [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN],
      },
      {
        path: "messages",
        element: Messages,
        roles: Object.values(SystemRolesEnum),
      },
      // EXCEPT
      {
        path: "*",
        element: Page404,
        roles: Object.values(SystemRolesEnum),
      },
    ],
  },
];

export default routes;
