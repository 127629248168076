import axios from "axios";
import UserService from "config/UserService";
import { API_CONTABLE, API_CUENTAS, API_INVENTARIO, API_WORKERS } from "config/api.config";

export const InventoryAxios = axios.create({
  baseURL: `${API_INVENTARIO}`,
  headers: { Authorization: UserService.token() },
});

export const AuthAxios = axios.create({
  baseURL: `${API_CONTABLE}`,
  headers: { Authorization: UserService.token() },
});

export const ContableAxios = axios.create({
  baseURL: `${API_CUENTAS}`,
  headers: { Authorization: UserService.token() },
});

export const apiWorkersClient = axios.create({
  baseURL: API_WORKERS,
  headers: { Authorization: UserService.token() },
});
