import {
  Business,
  Category,
  CategoryRounded,
  ChangeHistoryRounded,
  Class,
  Commute,
  DesktopMac,
  Games,
  SettingsOverscan,
  Weekend,
} from "@mui/icons-material";
import { QuarterlyModesType } from "interfaces/accounting";
import { EEGGPPParamsReportEnum, TypeAccountingReportsEnum, TypeReportEnum } from "interfaces/accounting.enum";
import { SystemRolesEnum } from "interfaces/global.enums";
import { TypeAssetsEnum, SurfacesUrlParams, AssetStatusEnum, GlobalModeModalStateEnum } from "interfaces/inventory.enums";
import {
  TypeAttendanceSignEnum,
  WorkerAttendanceImportTypes,
  WeeklyScheduleRangeTypeEnum,
  WorkerAttendancePermitStatusEnum,
} from "interfaces/workers.enums";

export const colorsWorkerTypeIncidents = {
  FALTA: "#FF4842",
  AMONESTACIONES: "#FFC107",
  VACACIONES: "#1890FF",
  FELICITACIONES: "#54D62C",
  OTROS: "#4d4d4d",
  "": "#000000",
};

export const baseListSecondFormat = [
  {
    date: "02/11/2021",
    action: "Se informó de los hechos y de las acciones realizadas a la UGEL.",
    detail: "Oficio Nro 001",
    type: "ACCIÓN",
  },
  {
    date: "04/11/2021",
    action: "Se comunicó el caso a la comisaría y fiscalía.",
    detail: "Oficio Nro 002",
    type: "DERIVACIÓN",
  },
  {
    date: "06/11/2021",
    action: "Se cambió de aula al agresor.",
    detail: "Oficio Nro 003",
    type: "SEGUIMIENTO",
  },
  {
    date: "08/11/2021",
    action: "Se convocó a los familiares de las o los involucrados para promover acciones en conjunto.",
    detail: "Oficio Nro 004",
    type: "ACCIÓN",
  },
  {
    date: "10/11/2021",
    action: "Se puso a dispoción de la UGEL al agresor.",
    detail: "Oficio Nro 005",
    type: "CIERRE",
  },
];

export const listParentesco = ["Padre", "Madre", "Hermano(a)", "Tio(a)", "Docente", "Otro"];
export const listTypeReport = ["Personal de la IE a estudiante", "Entre estudiantes"];
export const listTDocs = ["DNI", "RUC", "CE"];
export const listTypeAgresores = ["Estudiante", "Personal de la IE", "Otro"];
export const listPrtSchool = ["del mismo grado", "grado superior", "grado inferior"];
export const listSection = ["A", "B", "C", "D", "E"];
export const listLevels = ["INICIAL", "PRIMARIA", "SECUNDARIA"];
export const listShifts = ["MAÑANA", "TARDE", "NOCHE"];
export const listWorkerIncidents = ["FALTA", "AMONESTACIONES", "VACACIONES", "FELICITACIONES", "MATERNIDAD", "ENFERMEDAD", "PERMISOS", "OTROS"];
export const listSex = [
  { code: "M", label: "MASCULINO" },
  { code: "F", label: "FEMENINO" },
];
export const listRole = [
  { key: SystemRolesEnum.ACCOUNTANT, label: "CONTADOR" },
  { key: SystemRolesEnum.ADMIN, label: "ADMINISTRADOR" },
  { key: SystemRolesEnum.SUPERADMIN, label: "SUPERADMIN" },
];

export const covidTableList = [
  "Hipertensión arterial",
  "Enfermedad cardiovascular",
  "Diabetes Mellitus",
  "Obesidad con IMC de 40 a más",
  "Asma",
  "Enfermedad pulmonar crónica",
  "Insuficiencia renal crónica",
  "Enfermedad o tratamiento inmunosupresor",
  "Cáncer",
  "Otra condición de riesgo",
  "Sensación de alza térmica, fiebre o escalofríos",
  "Dolor de garganta o tos",
  "Estornudos o congestión nasal",
  "Dificultad para respirar",
  "Expectoración o flema amarilla o verdosa",
  "Dolor de músculos o articulaciones",
  "Dolor de cabeza",
  "Fatiga o malestar general",
  "Pérdida de gusto o de olfato",
  "Diarrea",
  "Contacto confirmado", //"Contacto con persona(s) con un caso confirmado de COVID-19",
  "Contacto sospechoso", //"Contacto con un caso sospechoso de COVID - 19",
  "¿Esta tomando alguna medicación?",
];

export const listMonths = [
  { label: "Enero", key: "01" },
  { label: "Febrero", key: "02" },
  { label: "Marzo", key: "03" },
  { label: "Abril", key: "04" },
  { label: "Mayo", key: "05" },
  { label: "Junio", key: "06" },
  { label: "Julio", key: "07" },
  { label: "Agosto", key: "08" },
  { label: "Septiembre", key: "09" },
  { label: "Octubre", key: "10" },
  { label: "Noviembre", key: "11" },
  { label: "Diciembre", key: "12" },
];

export const activeNavStyle = {
  color: "#fff",
  fontWeight: "fontWeightMedium",
  bgcolor: "primary.main",
  "&:before": { display: "block" },
  "&:hover": {
    backgroundColor: "primary.dark",
    color: "#fff",
  },
};

export const listCivilStatus = ["SOLTERO(A)", "CASADO(A)", "VIUDO(A)", "DIVORCIADO(A)"];

export const listReasonDown = [
  "RENUNCIA",
  "RENUNCIA CON INCENTIVO",
  "DESPIDO O DESTITUCIÓN",
  "CESE COLECTIVO",
  "JUBILACIÓN",
  "INVALIDES ABSOLUTA PERMANENTE",
  "TERMINACIÓN DE LA OBRA O SERVICIO O VENCIMIENTO DEL PLAZO",
  "MUTUO DISENSO",
  "FALLECIMIENTO",
  "BAJA POR SUCESIÓN EN POSICIÓN DEL EMPLEADOR",
  "EXTINCIÓN O LIQUIDACIÓN DEL EMPLEADOR",
  "NO SE INICIO LA REL. LABORAL O PREST. DE SERVICIOS",
];

export const listTypeDocs = [
  { key: "DNI", label: "DOCUMENTO NACIONAL DE IDENTIDAD", code: "01" },
  { key: "CNT_EXT", label: "CARNÉ DE EXTRANJERÍA", code: "04" },
  { key: "RUC", label: "REG. ÚNICO DE CONTRIBUYENTES", code: "06" },
  { key: "PASSPORT", label: "PASAPORTE", code: "07" },
  { key: "CARNÉ SOLIC REFUGIO", label: "CARNÉ DE SOLICITUD DE REFUGIO", code: "09" },
  { key: "PART. NAC.", label: "PARTIDA DE NACIMIENTO", code: "11" },
  { key: "C.IDENT.-RREE", label: "CARNÉ DE IDENTIDAD - RELACIONES EXTERIORES", code: "22" },
  { key: "PTP", label: "PERM.TEMP.PERMANENCIA", code: "23" },
  { key: "DOC.ID.EXTR.", label: "DOC. DE IDENTIDAD EXTRANJERO", code: "24" },
  { key: "CPP", label: "CARNÉ DE PERMISO TEMP DE PERMANENCIA", code: "26" },
];

export const listVouchersTypeDocs = [
  { key: "DNI", label: "DNI", code: 1 },
  { key: "RUC", label: "RUC", code: 6 },
  { key: "PASAPORTE", label: "PASAPORTE", code: 7 },
  { key: "CARNET_EXT", label: "CARNET DE EXTRANJERÍA", code: 4 },
];

export const entryTypeDocument = ["DNI", "RUC", "COMUNIDAD"];

export const entriesTypesDocuments = [
  { label: "APORTE 10 % SUELDO DE HERMANAS", key: "aporte_10" },
  { label: "APORTE DE COMUNIDADES A CAJA GENERAL", key: "aporte_caja" },
  { label: "USO DE ESPACIO", key: "uso_espacios" },
  { label: "ALQUILER DE INMUEBLES", key: "alquiler_inmuebles" },
  { label: "APORTE DE JUBILACIONES HERMANAS", key: "aporte_jubilacion" },
  { label: "LIQUIDACIONES DE HERMANAS", key: "liquidacion" },
  { label: "DEVOLUCIÓN DE CONTRIBUCIONES Y SUELDOS COMUNIDADES", key: "devolucion_contrbns" },
  { label: "DEVOLUCIÓN EPS SEGURO PACÍFICO", key: "devolucion_eps" },
  { label: "DEVOLUCIÓN SUELDOS TRABAJADORES", key: "devolucion_sueldos" },
  { label: "SEGURO VIDA LEY", key: "seguro_vida" },
  { label: "INGRESOS DE PARKINSONIA", key: "parkinsonia" },
  { label: "DONACIONES", key: "donaciones" },
  { label: "OTROS INGRESOS DIVERSOS", key: "otros" },
];

export const inventoryOptionsList = [
  {
    title: "TERRENOS",
    detail: "ACTIVO",
    icon: SettingsOverscan,
    to: `${SurfacesUrlParams.GROUNDS}/surfaces`,
  },
  {
    title: "EDIFICACIONES",
    detail: "ACTIVO",
    icon: Business,
    to: `${SurfacesUrlParams.EDIFICATIONS}/surfaces`,
  },
  {
    title: "EQUIPOS Y MAQUINARIA",
    detail: "ACTIVO",
    icon: DesktopMac,
    to: `${TypeAssetsEnum.MACHINERY}/assets`,
  },
  { title: "MUEBLES", detail: "ACTIVO", icon: Weekend, to: `${TypeAssetsEnum.FURNITURE}/assets` },
  { title: "VEHÍCULOS", detail: "ACTIVO", icon: Commute, to: `${TypeAssetsEnum.VEHICLES}/assets` },
  { title: "ENSERES", detail: "ACTIVO", icon: Category, to: `${TypeAssetsEnum.EQUIPMENT}/assets` },
  { title: "ARTÍCULOS RELIGIOSOS", detail: "ACTIVO", icon: Class, to: `${TypeAssetsEnum.RELIGIOUS}/assets` },
  {
    title: "INTANGIBLES",
    detail: "ACTIVO",
    icon: Games,
    to: `${TypeAssetsEnum.INTANGIBLES}/assets`,
  },
  { title: "CONSUMIBLE", detail: "ACTIVO", icon: Games, to: `consumible` },
];

export const surfacesSingular = {
  [SurfacesUrlParams.GROUNDS]: "ground",
  [SurfacesUrlParams.EDIFICATIONS]: "edification",
};

export const inventoryReportOptionsList = [
  { title: "LOCACIONES", icon: SettingsOverscan, to: `location` },
  { title: "CATEGORÍAS", icon: CategoryRounded, to: `categories` },
  { title: "SUBCATEGORÍAS", icon: ChangeHistoryRounded, to: `subcategories` },
];

export const colorAssetState = {
  [AssetStatusEnum.BUENO]: "#10b981",
  [AssetStatusEnum.REGULAR]: "#0ea5e9",
  [AssetStatusEnum.DETERIORADO]: "#002964",
  [AssetStatusEnum["FALTA ARREGLAR"]]: "#f59e0b",
  [AssetStatusEnum.MALOGRADO]: "#f43f5e",
  [AssetStatusEnum.DESUSO]: "#e11d48",
};

export const initialModalState = { mode: GlobalModeModalStateEnum.UNDEFINED, data: null };

export const buildTypeReportTitle = {
  [TypeAccountingReportsEnum.MAYOR]: {
    title: "MAYOR",
    subtitle: "MAYOR DE UNA CUENTA",
  },
  [TypeAccountingReportsEnum.AUX_ACCOUNTS]: {
    title: "CUENTAS AUXILIARES",
    subtitle: "CUENTAS AUXILIARES",
  },
  [TypeAccountingReportsEnum.BALANCE]: {
    title: "BALANCE",
    subtitle: "BALANCE DE N DÍGITOS HOJA DE TRABAJO",
  },
  [TypeAccountingReportsEnum.QUARTERLY]: {
    title: "REPORTE TRIMESTRAL",
    subtitle: "REPORTE TRIMESTRAL",
  },
  [TypeAccountingReportsEnum.BUDGET_EXECUTION]: {
    title: "EJECUCIÓN PRESUPUESTARIA",
    subtitle: "EJECUCIÓN PRESUPUESTARIA",
  },
  [TypeAccountingReportsEnum.BUDGET]: {
    title: "PRESUPUESTO",
    subtitle: "PRESUPUESTO",
  },
  [TypeAccountingReportsEnum.EEFF]: {
    title: "EEFF",
    subtitle: "ESTADO DE SITUACIÓN FINANCIERA",
  },
  [TypeAccountingReportsEnum.GENERAL_BALANCE]: {
    title: "ESTADO DE SITUACIÓN FINANCIERA DETALLADO",
    subtitle: "ESTADO DE SITUACIÓN FINANCIERA DETALLADO",
  },
  [TypeAccountingReportsEnum.EEGGPP]: {
    title: "EEGGPP",
    subtitle: "ESTADO DE RESULTADOS INTEGRAL POR NATURALEZA",
  },
  [TypeAccountingReportsEnum.EEGGPPNN]: {
    title: "ESTADO DE RESULTADO INTEGRAL POR NATURALEZA DETALLADO",
    subtitle: "ESTADO DE RESULTADO INTEGRAL POR NATURALEZA DETALLADO",
  },
  [TypeAccountingReportsEnum.CASH_FLOWS]: {
    title: "ESTADO DE FLUJOS DE EFECTIVO",
    subtitle: "MÉTODO INDIRECTO",
  },
  [TypeAccountingReportsEnum.CHANGE_HERITAGE]: {
    title: "ESTADO DE CAMBIO EN EL PATRIMONIO",
    subtitle: "ESTADO DE CAMBIO EN EL PATRIMONIO",
  },
  [TypeAccountingReportsEnum.DAILY_BOOK]: {
    title: "LIBRO DIARIO",
    subtitle: "LIBRO DIARIO",
  },
  [TypeAccountingReportsEnum.BOX_BOOK]: {
    title: "LIBRO CAJA Y BANCO",
    subtitle: "CAJA Y BANCO",
  },
  [TypeAccountingReportsEnum.INVENTORY_BOOK]: {
    title: "LIBRO DE INVENTARIOS Y BALANCES",
    subtitle: "INVENTARIOS Y BALANCES",
  },
  [TypeAccountingReportsEnum.MAYOR_BOOK]: {
    title: "LIBRO MAYOR",
    subtitle: "LIBRO MAYOR",
  },
  [TypeAccountingReportsEnum.CLOSING_SEAT]: {
    title: "BALANCE CON ASIENTO DE CIERRE",
    subtitle: "BALANCE DE N DÍGITOS CON ASIENTO DE CIERRE",
  },
  [TypeAccountingReportsEnum.SHOPPING_SALES_RECORD]: {
    title: "REGISTRO DE COMPRAS | VENTAS",
    subtitle: "REGISTRO DE COMPRAS | VENTAS",
  },
  [TypeAccountingReportsEnum.SHOPPING_RECORD]: {
    title: "REGISTRO DE COMPRAS",
    subtitle: "REGISTRO DE COMPRAS",
  },
  [TypeAccountingReportsEnum.SALES_RECORD]: {
    title: "REGISTRO DE VENTAS",
    subtitle: "REGISTRO DE VENTAS",
  },
  [TypeAccountingReportsEnum.RECEIPTS_FEES]: {
    title: "RECIBOS POR HONORARIOS",
    subtitle: "RECIBOS POR HONORARIOS",
  },
  [TypeAccountingReportsEnum.PATRIMONIO_REPORT]: {
    title: "ESTADO DE CAMBIO EN EL PATRIMONIO",
    subtitle: "POR EL AÑO TERMINADO AL 31 DE DICIEMBRE DEL AÑO ACTUAL",
  },
  [TypeAccountingReportsEnum.TICKETS]: {
    title: "REPORTE DE TICKETS",
    subtitle: "REPORTE DE TICKETS",
  },
};

export const accountingListReports = [
  {
    title: "UTILITARIOS",
    children: [
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.MAYOR].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.MAYOR].subtitle,
        path: TypeAccountingReportsEnum.MAYOR,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.AUX_ACCOUNTS].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.AUX_ACCOUNTS].subtitle,
        path: TypeAccountingReportsEnum.AUX_ACCOUNTS,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.BALANCE].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.BALANCE].subtitle,
        path: TypeAccountingReportsEnum.BALANCE,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.QUARTERLY].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.QUARTERLY].subtitle,
        path: TypeAccountingReportsEnum.QUARTERLY,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.BUDGET_EXECUTION].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.BUDGET_EXECUTION].subtitle,
        path: TypeAccountingReportsEnum.BUDGET_EXECUTION,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.BUDGET].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.BUDGET].subtitle,
        path: TypeAccountingReportsEnum.BUDGET,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.RECEIPTS_FEES].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.RECEIPTS_FEES].subtitle,
        path: TypeAccountingReportsEnum.RECEIPTS_FEES,
      },
      {
        name: "REPORTE TICKETS",
        detail: "REPORTE DE TICKETS",
        path: "tickets",
      },
    ],
  },
  {
    title: "ESTADOS FINANCIEROS",
    children: [
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.EEFF].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.EEFF].subtitle,
        path: TypeAccountingReportsEnum.EEFF,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.GENERAL_BALANCE].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.GENERAL_BALANCE].subtitle,
        path: TypeAccountingReportsEnum.GENERAL_BALANCE,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.EEGGPP].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.EEGGPP].subtitle,
        path: TypeAccountingReportsEnum.EEGGPP,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.EEGGPPNN].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.EEGGPPNN].subtitle,
        path: TypeAccountingReportsEnum.EEGGPPNN,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.PATRIMONIO_REPORT].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.PATRIMONIO_REPORT].subtitle,
        path: TypeAccountingReportsEnum.PATRIMONIO_REPORT,
      },
      /* {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.CASH_FLOWS].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.CASH_FLOWS].subtitle,
        path: TypeAccountingReportsEnum.CASH_FLOWS,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.CHANGE_HERITAGE].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.CHANGE_HERITAGE].subtitle,
        path: TypeAccountingReportsEnum.CHANGE_HERITAGE,
      }, */
    ],
  },
  {
    title: "LIBROS CONTABLE",
    children: [
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.DAILY_BOOK].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.DAILY_BOOK].subtitle,
        path: TypeAccountingReportsEnum.DAILY_BOOK,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.BOX_BOOK].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.BOX_BOOK].subtitle,
        path: TypeAccountingReportsEnum.BOX_BOOK,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.INVENTORY_BOOK].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.INVENTORY_BOOK].subtitle,
        path: TypeAccountingReportsEnum.INVENTORY_BOOK,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.MAYOR_BOOK].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.MAYOR_BOOK].subtitle,
        path: TypeAccountingReportsEnum.MAYOR_BOOK,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.CLOSING_SEAT].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.CLOSING_SEAT].subtitle,
        path: TypeAccountingReportsEnum.CLOSING_SEAT,
      },
      {
        name: buildTypeReportTitle[TypeAccountingReportsEnum.SHOPPING_SALES_RECORD].title,
        detail: buildTypeReportTitle[TypeAccountingReportsEnum.SHOPPING_SALES_RECORD].subtitle,
        path: TypeAccountingReportsEnum.SHOPPING_SALES_RECORD,
      },
      // {
      //   name: buildTypeReportTitle[TypeAccountingReportsEnum.SHOPPING_RECORD].title,
      //   detail: buildTypeReportTitle[TypeAccountingReportsEnum.SHOPPING_RECORD].subtitle,
      //   path: TypeAccountingReportsEnum.SHOPPING_RECORD,
      // },
      // {
      //   name: buildTypeReportTitle[TypeAccountingReportsEnum.SALES_RECORD].title,
      //   detail: buildTypeReportTitle[TypeAccountingReportsEnum.SALES_RECORD].subtitle,
      //   path: TypeAccountingReportsEnum.SALES_RECORD,
      // },
    ],
  },
];

export const parseTypesReportEnum = {
  [TypeReportEnum.DAY]: "DIARIO",
  [TypeReportEnum.MONTH]: "MENSUAL",
  [TypeReportEnum.YEAR]: "ANUAL",
  [TypeReportEnum.CUSTOM]: "PERSONALIZADO",
};

export const quarterlyButtonsOptions: Array<{ name: string; mode: QuarterlyModesType }> = [
  { name: "1ER TRIMESTRE", mode: 1 },
  { name: "2DO TRIMESTRE", mode: 2 },
  { name: "3ER TRIMESTRE", mode: 3 },
  { name: "4TO TRIMESTRE", mode: 4 },
  { name: "TOTAL", mode: 5 },
];

export const eeggppParamsReportLabels = {
  [EEGGPPParamsReportEnum.PRODUCTION_TITLE]: "PRODUCCIÓN",
  [EEGGPPParamsReportEnum.NET_SALES]: "VENTAS NETAS",
  [EEGGPPParamsReportEnum.DISCOUNTS]: "DESCUENTOS, REBAJAS Y BONIFICACIONES, CONC.",
  [EEGGPPParamsReportEnum.PRODUCTION_TOTAL]: "TOTAL",
  [EEGGPPParamsReportEnum.VALUE_ADDED_TITLE]: "VALOR AGREGADO",
  [EEGGPPParamsReportEnum.PURCHASES]: "COMPRAS (SUMINISTROS)",
  [EEGGPPParamsReportEnum.EXPENSES_FOR_SERVICES]: "GASTOS DE SERVICIOS PRESTADOS POR TERCEROS",
  [EEGGPPParamsReportEnum.VALUE_ADDED_TOTAL]: "TOTAL",
  [EEGGPPParamsReportEnum.GROSS_OPERATING_TITLE]: "EXCEDENTE BRUTO DE EXPLOTACIÓN",
  [EEGGPPParamsReportEnum.PERSONNEL_EXPENSES]: "GASTOS DE PERSONAL, DIRECTORES Y GERENTES",
  [EEGGPPParamsReportEnum.TAX_EXPENSES]: "GASTOS POR TRIBUTOS",
  [EEGGPPParamsReportEnum.GROSS_OPERATING_TOTAL]: "TOTAL",
  [EEGGPPParamsReportEnum.RESULT_EXPLOITATION_TITLE]: "RESULTADO DE EXPLOTACIÓN",
  [EEGGPPParamsReportEnum.OTHER_EXPENSES]: "OTROS GASTOS DE GESTIÓN",
  [EEGGPPParamsReportEnum.ASSETS_PROVISIONS]: "VALUACIÓN Y DETERIORO DE ACTIVOS Y PROVISIONES",
  [EEGGPPParamsReportEnum.OTHERS_INCOMES]: "OTROS INGRESOS DE GESTIÓN",
  [EEGGPPParamsReportEnum.RESULT_EXPLOITATION_TOTAL]: "TOTAL",
  [EEGGPPParamsReportEnum.RESULTS_BEFORE_TITLE]: "RESULTADO ANTES DE PARTICIPACIÓN E IMPUESTOS",
  [EEGGPPParamsReportEnum.FINANCIAL_INCOMES]: "INGRESOS FINANCIEROS",
  [EEGGPPParamsReportEnum.DISCOUNTS_BONUSES]: "DESCUENTOS, REBAJAS Y BONIFICACIONES OBTENIDAS",
  [EEGGPPParamsReportEnum.EARNINGS_PER_MONTH]: "GANANCIAS POR MED DE ACTIVOS FIN. AL VALOR RA",
  [EEGGPPParamsReportEnum.FINANCIAL_EXPENSES]: "GASTOS FINANCIEROS",
  [EEGGPPParamsReportEnum.RESULTS_BEFORE_TOTAL]: "TOTAL",
};

export const provisioningAccounts = ["12", "14", "16", "41", "42", "46"];

export const formVoucherDetailOptionalFields = ["origin", "operation_date", "cost", "costFormula", "aux_account", "ref_payment"];

export const excludeVoucherBoxFields = [
  "aux_account",
  "cn_correlative",
  "cn_emitiondate",
  "cn_serie",
  "cn_typedoc",
  "correlative",
  "cost",
  "customer_name",
  "customer_ndoc",
  "customer_tdoc",
  "ref_payment",
  "serie",
];

export const WEEK_DAYS_TRANSLATE = {
  monday: "lunes",
  tuesday: "martes",
  wednesday: "miércoles",
  thursday: "jueves",
  friday: "viernes",
  saturday: "sábado",
  sunday: "domingo",
};

export const TYPE_SIGNS_TRANSLATE = {
  [TypeAttendanceSignEnum.START]: "INICIO",
  [TypeAttendanceSignEnum.END]: "FIN",
};

export const defaultAttendancePhotoUrl = "https://filesym.s3.us-east-2.amazonaws.com/filesym/39a57766-4c0c-4467-bc4a-1c4f368e9174";

export const WORKER_ATTENDANCE_REGISTER_TYPES_TRANSLATE = {
  [WorkerAttendanceImportTypes.APPLICATION]: "App móvil",
  [WorkerAttendanceImportTypes.MANUAL]: "Manual",
  [WorkerAttendanceImportTypes.IMPORT]: "Importación",
  [WorkerAttendanceImportTypes.ZKTECO]: "ZKTeco",
};

export const WORKER_WK_HRS_TYPE_TRANSLATE = {
  [WeeklyScheduleRangeTypeEnum.NORMAL]: "Normal",
  [WeeklyScheduleRangeTypeEnum.RANGE]: "Escalonado",
  [WeeklyScheduleRangeTypeEnum.FREE]: "Libre",
};

export const WORKER_ATTENDANCE_PERMITS_TYPE_TRANSLATE = {
  [WorkerAttendancePermitStatusEnum.PENDING]: "Pendiente",
  [WorkerAttendancePermitStatusEnum.APPROVED]: "Aprobado",
  [WorkerAttendancePermitStatusEnum.REJECTED]: "Rechazado",
};
