import { createContext, ReactNode, useContext, useState, useEffect } from "react";
import StorageService from "config/StorageService";
import { SESSION_NAV, SESSION_USER } from "config/session";
import { ContextProps } from "interfaces/global";
import { CompanyProps } from "interfaces/company";
import { UserProps } from "interfaces/user";
import { HeadquarterProps } from "interfaces/headquarter";
import { SystemRolesEnum } from "interfaces/global.enums";
import useCoins from "hooks/useCoins";
import { getCostsFormula } from "requests/costs";
import { validTokenStatus } from "requests/user";

const NapContable = createContext({} as ContextProps);

export const useNapContableContext = () => useContext(NapContable);

const NapContableProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserProps>({});
  const [company, setCompany] = useState<CompanyProps>({});
  const [headquarter, setHeadquarter] = useState<HeadquarterProps>({});
  const [year, setYear] = useState(0);
  const hasAccess = [SystemRolesEnum.SUPERNAP, SystemRolesEnum.SUPERADMIN].includes(user?.rol!);
  const [costsFormula, setCostsFormula] = useState([]);

  const { coins, getCoins } = useCoins();

  const obtainCostsFormula = async (
    yearParam: number = year,
    headquarterId: string = headquarter?._id!
  ) => {
    if (yearParam && headquarterId) {
      const res = await getCostsFormula(yearParam, headquarterId);
      setCostsFormula(res);
    }
  };

  const validateSession = async () => {
    setLoading(true);
    // ACTUALIZAR LA INFORMACIÓN DE LA EMPRESA EN CADA RECARGA
    const data = StorageService.get(SESSION_USER);
    if (data) {
      const res = await validTokenStatus(data.user?._id, data.headquarter?._id);

      if (res && !res?.message) {
        setLoading(true);
        StorageService.remove(SESSION_USER);
        StorageService.remove(SESSION_NAV);
        window.location.href = `/${company.url}/login`;
      }

      setUser(res?.user ?? data.user);
      setCompany(res?.company ?? data.company);
      setHeadquarter(res?.headquarter ?? data.headquarter);

      setYear(data.year);
      obtainCostsFormula(data.year, res?.headquarter?._id ?? data.headquarter?._id);
      getCoins();
    }
    setLoading(false);
  };

  useEffect(() => {
    validateSession();
    // eslint-disable-next-line
  }, []);

  return (
    <NapContable.Provider
      value={{
        user,
        company,
        headquarter,
        year,
        setCompany,
        setHeadquarter,
        setYear,
        setUser,
        hasAccess,
        costsFormula,
        coins,
        loading,
        validateSession,
        obtainCostsFormula,
      }}
    >
      {children}
    </NapContable.Provider>
  );
};
export default NapContableProvider;
